import React from 'react';
import './Login.css';
import {getApiUrl} from '../../config';


function Login() {
    return (
        <div className="LoginPage">
            <a className="loginButtonText" href={getApiUrl()+"auth/login"}>Login with Spotify</a>
        </div>
    );
}

export default Login;
