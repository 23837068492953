import React, { useState, useEffect } from 'react';
import './WebPlayBack.css';
import MusicCard from "../../components/musicCard/musicCard";
import axios from "axios";
import {getApiUrl} from '../../config';
import Spinner from "../../components/spinner/Spinner";
import NotPlaying from "../notPlaying/NotPlaying";
import socket from "../../socket.io";

function WebPlayBack() {
    const [currentTrack, setCurrentTrack] = useState({
        name: '',
        artist: '',
        albumArt: '',
        uri: '',
        vote: 0
    });
    const [suggestions, setSuggestions] = useState([]);
    const [currentTrackIsLoading, currentTrackIsLoadingLoading] = useState(true);
    const [suggestionsAreLoading, setSuggestionsAreLoading] = useState(true);

    const fetchCurrentTrack = async () => {
        currentTrackIsLoadingLoading(true);
        const {data} = await axios({
            method: 'get',
            url: getApiUrl()+'spotify/track/current',
            headers: { }
        });
        if(data && data.track){
            setCurrentTrack({
                name: data.track.item.name,
                artist: data.track.item.artists.map((artist) => {
                    return artist.name;
                }),
                albumArt: data.track.item.album.images[0].url,
                uri: data.track.item.uri
            });
            currentTrackIsLoadingLoading(false);
        }
    };

    const fetchSuggestions = async () => {
        setSuggestionsAreLoading(true);
        const {data} = await axios({
            method: 'get',
            url: getApiUrl()+'spotify/suggestions',
            headers: { }
        });
        if(data && data.tracks && data.tracks.length > 0){
            setSuggestions(data.tracks);
            setSuggestionsAreLoading(false);
        }
    };

    useEffect(async () => {
        await fetchCurrentTrack();
        await fetchSuggestions();

        socket.on('playingNewTrack', async (data) => {
            currentTrackIsLoadingLoading(true);
            setCurrentTrack({
                name: data.track.item.name,
                artist: data.track.item.artists.map((artist) => {
                    return artist.name;
                }),
                albumArt: data.track.item.album.images[0].url,
                uri: data.track.item.uri
            });
            currentTrackIsLoadingLoading(false);
            setSuggestionsAreLoading(true);
            setSuggestions(data.suggestions);
            setSuggestionsAreLoading(false);
        });

        socket.on('refreshSuggestions', async (data) => {
            setSuggestionsAreLoading(true);
            setSuggestions(data.suggestions);
            setSuggestionsAreLoading(false);
        });
    }, []);

    return (
        <div className={'WebPlayBackPage'}>
            {/*{!currentTrackIsLoading ? <div className={'WebPlayBackPage-currentlyPlaying-backgroundImage'} style={{backgroundImage: 'url('+currentTrack.albumArt+')'}}/> : ''}*/}
            {currentTrack.name && !currentTrackIsLoading ?
                <>
                    <div className="WebPlayBackPage-currentlyPlaying">
                        <div className={'WebPlayBackPage-currentlyPlaying-backgroundImage'} style={{backgroundImage: 'url('+currentTrack.albumArt+')'}}/>
                        {currentTrack.albumArt !== '' ?
                            <img src={currentTrack.albumArt} className="WebPlayBackPage-currentlyPlaying-albumCover" alt="" />
                            :
                            ''
                        }
                        <div className="WebPlayBackPage-currentlyPlaying-info">
                            {currentTrack.name !== '' ?
                                <div className="WebPlayBackPage-currentlyPlaying-info-name">{currentTrack.name}</div>
                                :
                                ''
                            }
                            {currentTrack.artist !== '' ?
                                <div className="WebPlayBackPage-currentlyPlaying-info-artist">{currentTrack.artist}</div>
                                :
                                ''
                            }
                        </div>
                    </div>
                    {!suggestionsAreLoading && suggestions.length > 0 ?
                        <div className="WebPlayBackPage-voteMusicCards">
                            {suggestions.map((suggestion, index) => {
                                if(suggestion !== undefined){
                                    let artist = '';
                                    if(suggestion.artist.length > 1){
                                        suggestion.artist.forEach((artistName, index) => {
                                            if(index+1 === suggestion.artist.length){
                                                artist = artist+artistName;
                                            } else {
                                                artist = artist+artistName+', ';
                                            }
                                        });
                                    } else {
                                        artist = suggestion.artist;
                                    }
                                    return <MusicCard
                                        key={index}
                                        albumUrl={suggestion.albumArt}
                                        name={suggestion.name}
                                        artistName={artist}
                                        uri={suggestion.uri}
                                        color={suggestion.color}
                                    />;
                                }
                            })}
                        </div> :
                        <Spinner/>
                    }
                </>
                :
                <NotPlaying/>
            }
        </div>
    );
}

export default WebPlayBack;
