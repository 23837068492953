import React, { useState, useEffect }  from 'react';
import './App.css';
import WebPlayback from './pages/webPlayback/WebPlayBack';
import Login from './pages/login/Login';
import axios from "axios";
import {getApiUrl} from "./config";
import socket from "./socket.io";

function App() {
    const [token, setToken] = useState('');

    async function getToken() {
        const response = await axios(getApiUrl()+'auth/token');
        const data = await response.data;
        setToken(data.loggedIn);
    }

    useEffect(async () => {
        await getToken();
        socket.on('loginEvent', async () => {
            await getToken();
        });

        socket.on('logoutEvent', async () => {
            await getToken();
        });
    }, []);

    return (
        <div className={'App'}>
            { (token === false) ? <Login/> : <WebPlayback token={token}/> }
        </div>
    );
}

export default App;
