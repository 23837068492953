import React from 'react';
import './musicCard.css';
import axios from "axios";
import {getApiUrl} from '../../config';

function MusicCard(props) {
    const clickHandler = async () => {
        await axios.post(
            getApiUrl()+'spotify/vote',
            {track: props.name}
        );
    };

    return (
        <div className={'musicCard'} onClick={clickHandler} style={{backgroundColor: props.color}}>
            <img src={props.albumUrl} alt="albumArt" className={"musicCard-albumArt"}/>
            <div className="musicCard-text">
                <p className={'musicCard-text-name'}>{props.name}</p>
                <p className={'musicCard-text-artistName'}>{props.artistName}</p>
            </div>
        </div>
    );
}

export default MusicCard;
