import React from 'react';
import './NotPlaying.css';

function NotPlaying(props) {
    return (
        <div className={'notPlayingPage'}>
            <p className={'notPlayingPage-text'}>
                Please start playing a song from your playlist and refresh the page
            </p>
        </div>
    );
}

export default NotPlaying;
